import { Component } from '@angular/core';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './app-main-layout.component.html',
  styleUrl: './app-main-layout.component.css'
})
export class MainLayoutComponent {
  constructor(public authService: AuthService){

  }

}
