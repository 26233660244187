<nav class="navbar navbar-expand-lg navbar-light" style="background-color: #4B6299;">
  <div class="container">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse d-flex justify-content-center" id="navbarNav">
          <ul class="navbar-nav">
              <li class="nav-item">
                  <a class="nav-link" href="#">Home</a>
              </li>
              <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="membershipDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Membership
                  </a>
              </li>
              <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="eventsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Events
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="eventsDropdown">
                      <!-- Submenu options will be populated by API call -->
                  </ul>
              </li>
              <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="initiativesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Initiatives
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="initiativesDropdown">
                      <!-- Submenu options will be populated by API call -->
                  </ul>
              </li>
              <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="fundraisingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Fundraising
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="fundraisingDropdown">
                      <!-- Submenu options will be populated by API call -->
                  </ul>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="#">Sponsorship</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="#">Volunteer</a>
              </li>
              <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="aboutDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      About
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="aboutDropdown">
                      <li><a class="dropdown-item" href="#">PTO team</a></li>
                      <li><a class="dropdown-item" href="#">ByLaws</a></li>
                      <li><a class="dropdown-item" href="#">Meeting and Minutes</a></li>
                      <li><a class="dropdown-item" href="#">Finances</a></li>
                  </ul>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="#">Gallery</a>
              </li>
          </ul>
      </div>
  </div>
</nav>
