import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginClient, LoginCommand, LoginDto } from 'src/app/web-api-client';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = 'YOUR_API_URL'; // replace with your API URL

  constructor(private loginClient: LoginClient) {}

  login(username: string, password: string): Observable<LoginDto> {
    let loginCommand = new LoginCommand({
      username: username,
      password: password
  });
    return this.loginClient.loginUser(loginCommand);
    //return this.http.post<string>(`${this.apiUrl}/login`, { username, password });
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('jwt');
  }

  logout() {
    localStorage.removeItem('jwt');
  }
}
